<template>
  <div class="mister">
      <div class="uk-card uk-card-default uk-card-body ">
          <h1 class="uk-card-title">
              <slot name="pageTitle">{{ translate('sgirot.expert.mainTitle') }}</slot>
          </h1>
          <div class="page-content">
              <crud-list
                    :enableIdColumn="true"
                      subject="expert"
                      :tableColumns="tableColumns"
                      :filterFields="filterFields"
              >


                  <template v-slot:cellContent="{item, col, colIndex}">
                      <div v-if="col == 'debt'">
                          <span v-if="! item[col] ||  item[col] == '0'" class="no-debt-col uk-text-success">
                               {{ translate('sgirot.expert.debtColumnNoDebt') }}
                          </span>
                          <span v-else class="debt-column uk-text-danger uk-flex uk-flex-start uk-flex-middle" style="gap: 4px">
                              <span uk-icon="warning"></span>
                              <span>{{ translateNumber(item[col])}}</span>
                          </span>

                      </div>
                      <span v-else>
                           {{ formatCellData(col, item[col]) }}
                      </span>

                  </template>
              </crud-list>
          </div>
      </div>

  </div>
</template>

<script>

import CrudList from '@/client/applications/sgirot/components/crud/List.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";

export default {

    setup () {
        return  useMeta({
            title: useI18n().t('sgirot.expert.mainTitle')
       }).meta
    },
    props: {

    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data () {
        return {
            entityType: 'source',
            tableColumns: {
                'commissionPercent' : {},
                'debt' : {
                    sortable: false,
                },
                'email' : {},
                'fullName' : {}
            },
            filterFields : {
                'search' : {
                    name: 'search',
                    label: false,
                    placeholder: 'sgirot.expert.fields.listFilterSearchPlaceholder',
                    type: 'text',
                }
            },
        }
    },
    components: {CrudList},
    layout: 'dashboard',
    methods: {
        formatCellData(col, value) {
            if (this.tableColumns[col] && this.tableColumns[col].cellFormatter) {
                return this.tableColumns[col].cellFormatter(value);
            }
            return value;
        },
        getEditRoute(id) {
            return {name: 'entity-edit', params: {entityType: this.entityType, entityId: id}}
        },
        getUpdateApiUrl(arg) {
            return 'entity/'+this.entityType+'/'+arg;
        },
    }
}
</script>
